<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <b-form @submit.prevent>
          <app-collapse>
            <app-collapse-item
                :is-visible="isVisible"
                title="Búsqueda"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Gerencia
                    </h5>
                    <autocomplete-infinity
                        id="id_gerencia"
                        url="dash/mutual/filtro/gerencia"
                        placeholder="Seleccione Gerencia"
                        label="gerencia"
                        items-per-page="10"
                        @item-selected="onGerenciaChange"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </b-card>
      <b-card>
        <div v-if="visibleReport">
          <div class="demo-inline-spacing" >
            <b-form-group>
              <h5 class="font-weight-bold">
                Promedio de porcentaje de avances de los alumnos por cursos y gerencia
              </h5>
            </b-form-group>
          </div>
          <div class="demo-inline-spacing" v-if="showColumn">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="columnsDefault"
            >
              Columnas por Defecto
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="checkAllColumns"
            >
              <feather-icon
                  icon="check"
                  class="mr-50"
              />
              <span class="align-middle">Todas las Columnas</span>
            </b-button>
          </div>
          <div class="demo-inline-spacing ml-1" v-if="showColumn">
            <b-form-checkbox
                v-for="column in columns"
                v-model="column.display"
                name="flavour-4a"
                inline
            >
              {{ column.label }}
            </b-form-checkbox>
          </div>
          <b-row class="pr-1 pl-1">
            <b-col sm="12" md="6"  class="my-1 text-right">
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                    @change="getList"
                    id="perPageSelect"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    class="mx-50 col-md-2"
                ></b-form-select>
                <label>filas por página</label>
              </div>
            </b-col>
            <b-col sm="4" md="6" class="my-1">
              <div class="d-flex justify-content-end">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="showColumns"
                >
                  <feather-icon v-if="!showColumn"
                                icon="EyeIcon"
                                class="mr-50"
                  />
                  <feather-icon v-if="showColumn"
                                icon="EyeOffIcon"
                                class="mr-50"
                  />
                  <span class="align-middle">{{showColumnText}}</span>

                </b-button>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    class="ml-1"
                    @click="exportar"
                >
                  <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Exportar</span>

                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-col cols="12" v-if="showTable">
            <b-table
                striped
                hover
                small
                :per-page="perPage"
                :items="listado"
                :fields="filteredHeaders"
                responsive
                class="mb-0"
                id="my-table"
                ref="table"
                show-empty
            >

              <template #empty="scope">
                <div class="text-center">No existen resultados</div>
              </template>
            </b-table>

          </b-col>
          <b-row class="pl-1">
            <b-col  cols="8" class="mt-3">
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                  @change="onPageChange"
                  v-model="page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  class="mt-3 mr-1"
                  aria-controls="my-table"
              />
            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge,
  BButton, BCard,
  BCol, BFormGroup, BFormSelect, BPagination,
  BRow,
  BTable, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import consolidadoGerencia from "@/store/modules/consolidadoGerencia";
export default {
  name: "consolidadoGerencia",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    BTable,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    BFormSelect,
    BInputGroup,
    flatPickr,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      perPage: 50,
      page: 1,
      pageOptions: [50, 100, 500, 1000],
      visibleReport : true,
      showTable: true,
     // fields: [],
      columnsData: [],
      showColumn: false,
      showColumnText: 'Mostrar Columnas',
      isVisible: true,
      filter:{
        gerencia:''
      }
    }
  },
  mounted() {
    this.getColumns()
    this.getList()
  },
  computed: {
    totalRows() {
      return this.$store.state.consolidadoGerencia.rowsTotal
    },
    to() {
      return this.$store.state.consolidadoGerencia.toSelect
    },
    from() {
      return this.$store.state.consolidadoGerencia.from
    },
    lastPage() {
      return this.$store.state.consolidadoGerencia.lastPage
    },
    listado() {
      return this.$store.state.consolidadoGerencia.list
    },
    columns() {
      const data = this.$store.state.consolidadoGerencia.columns
      this.columnsData = []
      for (const key in data) {
        let column
        if(key < 11)
        {
           column = {
            key: data[key],
            label: data[key],
            display: true,
            default: true,
          }
        }
        else
        {
           column = {
            key: data[key],
            label: data[key],
            display: false,
            default: false,
          }
        }

        this.columnsData.push(column)
      }
      return this.columnsData
    },
    filteredHeaders() {
      return this.columns.filter(h => h.display === true)
    },
    currentPage() {
      return this.$store.state.consolidadoGerencia.currentPage
    },
    loading() {
      return this.$store.state.consolidadoGerencia.loading
    },

    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
  },
  methods: {
    columnsDefault() {
      for (let key in this.columnsData) {
        this.columnsData[key].display = this.columnsData[key].default;
      }
    },
    checkAllColumns() {
      for (let key in this.columnsData) {
        this.columnsData[key].display = true;
      }
    },
    getColumns() {
      let filterData = {
        'page': this.page,
        'perPage': this.perPage,
        'gerencia': this.filter.gerencia
      }
      this.$store.dispatch('consolidadoGerencia/getColumns', filterData)
    },
    getList() {
      this.visibleReport = true;
      let filterData = {
        'page': this.page,
        'perPage': this.perPage,
        'gerencia': this.filter.gerencia
      }

      this.$store.dispatch('consolidadoGerencia/getList', filterData)
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onGerenciaChange(gerencia){
      this.filter.gerencia = gerencia != null ? gerencia.id_gerencia : ''
      this.getList()
    },
    showColumns() {
      this.showColumn = this.showColumn == true ? false : true;
      this.showColumnText = this.showColumn == true ? 'Ocultar Columnas' : 'Mostrar Columnas';
    },
    exportar() {
      if (this.listado.length == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: `ALERTA!!`,
          variant: 'warning',
          solid: true,
        })
      } else {
        this.getExportData();
      }
    },
    getExportData() {
      let fieldsV = [];
      Object.entries(this.columnsData).forEach(entry => {
        const [key, value] = entry;
        if (value.display) {
          fieldsV.push(value.key);
        }
      });
      let filterData = {
      //  'fields': fieldsV,
        'page': this.page,
        'perPage': this.perPage,
      }
      this.$store.dispatch('consolidadoGerencia/exportData', filterData)
    },
  },
}
</script>

<style scoped>

</style>